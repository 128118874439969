<template>
  <button class="btn_primary_qe" :disabled="disabled || loading">
    <SpinnerComponent
      :spinner-color="'var(--primary_white)'"
      :type="'small'"
      v-if="loading"
    >
    </SpinnerComponent>
    <svg
      v-if="has_plus"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-plus"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M12 5l0 14"></path>
      <path d="M5 12l14 0"></path>
    </svg>
    <slot></slot>
  </button>
</template>

<script>
import SpinnerComponent from "@/components/new/buttons/SpinnerComponent";
export default {
  components: { SpinnerComponent },
  props: ["disabled", "loading", "has_plus"],
};
</script>

<style scoped>
button {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 8px;
  color: var(--primary_white);
  font-size: 14px;
  font-weight: var(--medium);
  font-family: "Gilroy";
  cursor: pointer;
  transition: 0.3s ease all;
}

.btn_primary_qe:hover {
  background-color: #fc7748;
  border: 1px solid #fc7748;
}

.btn_primary_qe:active {
  background-color: #e3541f;
  border: 1px solid #e3541f;
}

.btn_primary_qe[disabled] {
  background-color: #fdae94;
  border: 1px solid #fdae94;
  cursor: not-allowed;
}
</style>
