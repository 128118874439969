import axios from "axios";

export default {
  async updateRegId(data) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/user/reg_id/`,
      data,
    });
  },
  async changeAdminPassword(data) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/user/admin/password/`,
      data,
    });
  },

  async getValidUsers() {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/historic/users/total/`,
    });
  },

  async getUserData(
    data = {
      user_list: [],
    }
  ) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/historic/users/total/`,
      data,
    });
  },

  async getDeletedUsers() {
    return await axios({
      method: "DELETE",
      url: `${process.env.VUE_APP_API}/historic/users/total/`,
    });
  },
  async getCustomerInfo(data) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/user/notes/`,
      data,
    });
  },
  async updateUserNotes(data) {
    return await axios({
      method: "PUT",
      url: `${process.env.VUE_APP_API}/user/notes/`,
      data,
    });
  },

  async infoUser() {
    return await axios({
      method: "GET",
      url: `${process.env.VUE_APP_API}/user/staff/`,
    });
  },
  async login(data) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/account/signin/admin/`,
      data,
    });
  },

  async logoutUserRegId(data) {
    return await axios({
      method: "DELETE",
      url: `${process.env.VUE_APP_API}/account/logout/`,
      data,
    });
  },

  async search_user(phone) {
    return await axios({
      params: {
        phone,
      },
      method: "GET",
      url: `${process.env.VUE_APP_API}/user/callcenter/`,
    });
  },

  async newUserCallCenter(
    data = {
      first_name: "",
      last_name: "",
      phone: "",
      latitude: "",
      longitude: "",
      address: "",
      reference: "",
      is_delivery: false,
      national_id: "",
    }
  ) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/user/callcenter/`,
      data,
    });
  },

  async newAddressAppUser(
    data = {
      user_id: "",
      latitude: "",
      longitude: "",
      address: "",
      reference: "",
      is_delivery: false,
      national_id: "",
    }
  ) {
    return await axios({
      method: "PUT",
      url: `${process.env.VUE_APP_API}/user/callcenter/`,
      data,
    });
  },

  async searchAppUser(phone) {
    return await axios({
      params: {
        phone,
      },
      method: "GET",
      url: `${process.env.VUE_APP_API}/user/crm/points/`,
    });
  },

  async updateUserPoints(data) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/user/crm/points/`,
      data,
    });
  },

  async redeemUserCoupon(data) {
    return await axios({
      method: "PUT",
      url: `${process.env.VUE_APP_API}/user/crm/points/`,
      data,
    });
  },

  async uploadExcelWithPoints(data) {
    return await axios({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
      url: `${process.env.VUE_APP_API}/user/crm/points/file`,
      data,
    });
  },

  async notifyUser(
    data = {
      user: 0,
      message: "",
      order: 0,
    }
  ) {
    return await axios({
      method: "POST",
      url: `${process.env.VUE_APP_API}/user/notification/`,
      data,
    });
  },

  async listAddress() {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API}/user/address/`,
    });
  },

  async addAddress(data) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API}/user/address/`,
      data,
    });
  },
  async setBusinessHeader(name_app) {
    axios.defaults.headers.common["business"] = name_app;
  },
  async setAuthHeaders(accessToken) {
    axios.defaults.headers.common["platform"] = "Web";
    axios.defaults.headers.common["device"] = "Web";
    if (localStorage.getItem("businessIdentifier") !== null) {
      axios.defaults.headers.common["business"] = localStorage.getItem("businessIdentifier").replace(/^"(.*)"$/, '$1'); // Elimina las comillas externas
    }
    if (accessToken != null) {
      axios.defaults.headers.common.Authorization = "token " + accessToken;
    } else {
      axios.defaults.headers.common.Authorization = "";
    }
  },
};
