<template>
  <div
    class="base_component"
    v-if="status !== null && status !== undefined"
    :class="status === 'caution' ? 'caution__badge' : 'danger__badge'"
  >
    <slot></slot>
  </div>
  <div class="base_component" v-else>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "StatusBadge",
  components: {},
  props: ["status"],
};
</script>

<style scoped>
.base_component {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 2px 10px;
  background-color: var(--satisfying_bg);
  color: var(--satisfying);
  border: 1px solid var(--satisfying);
  border-radius: 16px;
  font-size: 14px;
  font-weight: var(--medium);
  font-family: "Gilroy";
  transition: 0.3s ease all;
}

.caution__badge {
  background-color: var(--primary_white);
  color: var(--primary_yellow);
  border: 1px solid var(--primary_yellow);
}

.danger__badge {
  background-color: var(--error_bg);
  color: var(--error);
  border: 1px solid var(--error);
}
</style>
