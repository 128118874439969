<template>
  <button class="default" :disabled="disabled || loading">
    <SpinnerComponent
      :spinner-color="'var(--primary)'"
      :type="'small'"
      v-if="loading"
    >
    </SpinnerComponent>
    <slot></slot>
  </button>
</template>

<script>
import SpinnerComponent from "@/components/new/buttons/SpinnerComponent";
export default {
  name: "ButtonTexto",
  components: { SpinnerComponent },
  props: ["disabled", "loading"],
};
</script>

<style scoped>
button {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 8px;
  color: var(--primary);
  font-size: 14px;
  font-weight: var(--medium);
  font-family: "Gilroy";
  cursor: pointer;
  transition: 0.3s ease all;
}
.default:hover {
  color: #fc7748;
  border: 1px solid transparent;
}

.default:active {
  color: #e3541f;
  border: 1px solid transparent;
}

.default[disabled] {
  background-color: transparent;
  border: transparent;
  color: #fdae94;
  cursor: not-allowed;
}
</style>
