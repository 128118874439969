<template>
  <div class="fondo_impresion">
    <div v-if="orderDetails[0].order !== null">
      Orden: {{ orderDetails[0].order }}
    </div>
    <div v-else>
      {{ orderDetails[0].table_name }} / {{ orderDetails[0].owner_name }}
    </div>

    <table>
      <tr class="table_header_print">
        <th>Cant.</th>
        <th>Producto</th>
      </tr>
      <tr
        v-for="item in orderDetails"
        :key="item.id"
        style="border-bottom: dashed; border-bottom-color: black"
      >
        <td style="width: 10%; border-right: dashed">
          <div class="item_cantidad_cont_print">{{ item.cant }}</div>
        </td>
        <td style="width: 90%">
          <div class="info_print">
            <div>
              <p style="margin-bottom: 0">
                {{ item.product_name }}
              </p>
              <ul style="margin-bottom: 0">
                <li v-for="size in item.product_size_names" :key="size">
                  a: {{ size }}
                </li>
                <li
                  v-if="
                    item.product_sizes2_names !== null &&
                    item.product_sizes2_names.length > 0
                  "
                >
                  <ul>
                    <li v-for="size in item.product_sizes2_names" :key="size">
                      b: {{ size }}
                    </li>
                  </ul>
                </li>
                <li
                  v-if="
                    item.product_sizes3_names !== null &&
                    item.product_sizes3_names.length > 0
                  "
                >
                  <ul>
                    <li v-for="size in item.product_sizes3_names" :key="size">
                      c: {{ size }}
                    </li>
                  </ul>
                </li>
                <li
                  v-if="
                    item.product_sizes4_names !== null &&
                    item.product_sizes4_names.length > 0
                  "
                >
                  <ul>
                    <li v-for="size in item.product_sizes4_names" :key="size">
                      d: {{ size }}
                    </li>
                  </ul>
                </li>
                <li v-if="item.ingredients_add.length > 0">
                  Extras:
                  <ul>
                    <li
                      v-for="ingredient in item.ingredients_add"
                      :key="ingredient"
                    >
                      - {{ ingredient }}
                    </li>
                  </ul>
                </li>
              </ul>
              <p style="margin-bottom: 0" v-if="item.comments">
                Obs: {{ item.comments }}
              </p>
            </div>
          </div>
        </td>
      </tr>
    </table>
    <div v-if="orderDetails[0].order !== null">
      Cliente:&nbsp;{{ orderDetails[0].owner_name }}
    </div>
    <div>Canal: {{ canal_pedido(orderDetails[0].type_delivery) }}</div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ModalPrintKitchenInfo",
  emits: ["finish"],
  data() {
    return {};
  },
  mounted() {
    setTimeout(() => {
      var original_height;
      original_height = document.getElementById("app").style.height;
      var original_width;
      original_width = document.getElementById("app").style.width;
      document.getElementById("app").style.width = "80mm";
      document.getElementById("app").style.overflow = "hidden";

      try {
        // Print for Safari browser
        document.execCommand("print", false, null);
      } catch {
        window.print();
      }
      setTimeout(() => {
        document.getElementById("app").style.height = original_height;
        document.getElementById("app").style.width = original_width;
        document.getElementById("app").style.minHeight = "100vh";
        document.getElementById("app").style.overflow = "unset";
      }, 150);

      this.emitClose();
    }, 350);
  },
  methods: {
    emitClose() {
      this.$emit("finish");
    },
    canal_pedido(type_delivery) {
      switch (type_delivery) {
        case "1":
          return "Salón";
        case "2":
          return "Recojo";
        case "3":
          return "Delivery";
        case "4":
          return "Venta rápida";
        default:
          return "Externo";
      }
    },
  },

  computed: {
    ...mapState(["orderDetails"]),
  },
};
</script>

<style scoped>
@media print {
  .fondo_impresion {
    width: 100%;
    margin: 0;
    float: none;
  }
}
@page :left {
}
@page :right {
}

.fondo_impresion {
  font-family: Lucida Sans Typewriter Two;
  font-weight: bold !important;
  height: auto;
  font-size: 13pt !important;
  background: #fff !important;
  color: #000 !important;
}
.table_header_print {
  border-style: dashed;
  border-color: #000;
  font-size: 12pt;
}
.item_cantidad_cont_print {
  text-align: center;
}
.info_print {
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  margin-left: 5px;
}
</style>
