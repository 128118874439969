<template>
  <div id="app__wrapper">
    <RebrandHeader :style="documentTypeForPrint > 0 ? 'display:none' : ''">
    </RebrandHeader>
    <transition name="slide-fade">
      <ModalLogin
          v-if="!isAuthenticated && showModalLoginRegister"
          :active="showModalLoginRegister"
      ></ModalLogin>
    </transition>
    <main class="main" v-show="documentTypeForPrint === 0">
      <router-view> </router-view>
    </main>
    <ModalPrintOrderInfo
        v-if="documentTypeForPrint === 1"
        @finish="finished_printing"
    >
    </ModalPrintOrderInfo>
    <ModalPrintInvoice
        v-if="documentTypeForPrint === 2"
        @finish="finished_printing"
    >
    </ModalPrintInvoice>
    <ModalPrintKitchenInfo
        v-if="documentTypeForPrint === 3"
        @finish="finished_printing"
    >
    </ModalPrintKitchenInfo>
    <transition name="slide-fade">
      <SingleButtonGenericModal
          v-if="showModalOptionalNotification"
          :title="''"
          :content="'Por favor, habilita las notificaciones en tu navegador web'"
          :accept="'Regresar'"
          @accepted_modal="showModalOptionalNotification = false"
          @close_modal="showModalOptionalNotification = false"
      >
      </SingleButtonGenericModal>
    </transition>

    <transition name="slide-fade">
      <SingleButtonGenericModal
          v-if="showModalNotificationsAllowed"
          :title="''"
          :content="'Notificaciones activadas con éxito'"
          :accept="'Regresar'"
          @accepted_modal="showModalNotificationsAllowed = false"
          @close_modal="showModalNotificationsAllowed = false"
      >
      </SingleButtonGenericModal>
    </transition>

    <transition name="slide-fade">
      <ModalWaiterNotificationRequest
          v-if="showModalConfirmNotification"
        :title="'Acción necesaria'"
        :content="'Para continuar, debe aceptar recibir notificaciones a su dispositivo'"
        :accept="'Aceptar'"
        :cancel="'Salir'"
        @close_modal="showModalConfirmNotification = false"
        @accepted_modal="requestPermissionWaiter"

      ></ModalWaiterNotificationRequest>
    </transition>

    <transition name="slide-fade">
      <SingleButtonGenericModal
          v-if="showPushUpModal"
          :title="notification.title"
          :content="notification.content"
          :accept="'Aceptar'"
          @accepted_modal="showPushUpModal = false"
          @close_modal="showPushUpModal = false"
      >
      </SingleButtonGenericModal>
    </transition>

  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import UserServices from "@/services/user-services";
import router from "./router";
import { db } from "@/firebase";
import { onSnapshot, collection } from "firebase/firestore";
import * as Sentry from "@sentry/vue";
import store from "@/store";
import ModalPrintOrderInfo from "@/components/MODALS/ModalPrintOrderInfo";
import ModalPrintInvoice from "@/components/MODALS/ModalPrintInvoice";
import moment from "moment";
import {firebaseConfig, messaging, firebase, firebaseApp} from "@/firebase";
import RebrandHeader from "@/components/MainHeader";
import ModalLogin from "@/components/MODALS/ModalLogin";
import {getToken, onMessage} from "firebase/messaging";
import ModalPrintKitchenInfo from "@/components/MODALS/ModalPrintKitchenInfo";
import ModalWaiterNotificationRequest from "@/components/MODALS/ModalWaiterNotificationRequest.vue";
import SingleButtonGenericModal from "@/components/Recursos/SingleButtonGenericModal.vue";

export default {
  components: {
    SingleButtonGenericModal,
    ModalWaiterNotificationRequest,
    ModalPrintKitchenInfo,
    ModalLogin,
    RebrandHeader,
    ModalPrintInvoice,
    ModalPrintOrderInfo,
  },
  computed: {
    ...mapState([
        "businessIdentifier",
      'businessLocalId',
      "showModalLoginRegister",
      "isAuthenticated",
      "configurationsAdminWeb",
      "user",
      "localSuperAdmin",
      "webVersion",
      "documentTypeForPrint",
        'platform'
    ]),
  },
  data() {
    // documentTypeForPrint: 1 comanda, 2 boleta/factura/nota
    return {
      showPushUpModal: false,
      notification: {
        title: '',
        content: ''
      },
      initialListen: true,
      showModalNotificationsAllowed: false,
      showModalOptionalNotification: false,
      showModalConfirmNotification: false,
      current_state_notif: false,
      subscription_firebase: null,
      fechaLimite: "",
      fechaActual: moment(),
      mensaje_moroso: "Servicio suspendido hasta que regularicen su pago.",
      myTrack: null,
      has_loaded_worker: false,
    };
  },

  watch: {
    isAuthenticated: async function () {
      this.initialListen = true;
      if (this.subscription_firebase !== null) {
        console.log("desactivara firestore");
        this.subscription_firebase();
        this.subscription_firebase = null;
      }
      if (this.isAuthenticated) {
        if (this.user.force_logout) {
          await this.logoutAndClearData();
          window.alert(this.mensaje_moroso);
          return;
        }
        if (this.user.waiter) {
          // this.showModalConfirmNotification = true // TODO: aqui se activan notificaciones push de mesero


          // navigator.serviceWorker.addEventListener('message', (event) => {
          //   if (event.data.type === 'showModal') {
          //     // Muestra el modal con los datos recibidos
          //     this.notification.title = event.data.title;
          //     this.notification.content = event.data.content;
          //     this.showPushUpModal = true
          //   }
          // });
          return
        }
        if (this.user.is_staff || this.user.super_admin || this.user.admin) {
          console.log("llamara firestore");
          setTimeout(async () => {
            await this.activeOrdersNotificationPermissions();
            await this.firestoreListennerApp();
          }, 500);
          return;
        }
        if (this.user.business_staff && this.user.access_list.includes("1")) {
          console.log("firestore business_staff");
          setTimeout(async () => {
            await this.activeOrdersNotificationPermissions();
            await this.firestoreListennerApp();
          }, 500);
        }
      } else {
        this.unregisterServiceWorker()
      }
    },
  },
  async created() {
    var metaThemeColor = document.querySelector("meta[name=theme-color]");
    metaThemeColor.setAttribute(
        "content",
        getComputedStyle(document.body).getPropertyValue("--primary")
    ); //setea el theme color desde una variable css
    console.log("connection is", process.env.NODE_ENV);
  },
  async mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    this.fechaLimite = moment({
      year: this.fechaActual.year(),
      month: this.fechaActual.month(),
      date: 16,
      hour: 23,
      minute: 59,
      second: 0,
    });

    setTimeout(() => {
      if (process.env.NODE_ENV !== "development") {
        console.log("no es dev");
        if (this.isAuthenticated) {
          Sentry.setUser({
            email: this.user.email,
            phone: this.user.phone,
            full_name: this.user.first_name + " " + this.user.last_name,
            business: this.businessIdentifier,
          });
        } else {
          Sentry.configureScope((scope) => scope.setUser(null));
        }
      }
    }, 500);

    setTimeout(async () => {
      console.log("businessIdentifier else", this.businessIdentifier)
      if (this.businessIdentifier !== '') {
        if (this.isAuthenticated) {
          await UserServices.setAuthHeaders(this.user.token);
          await UserServices.setBusinessHeader(this.businessIdentifier);
          await this.getUserPermissions();
        }
      } else {
        console.log("llama else")
        await this.logoutAndClearData();
        return
      }
      if (this.webVersion < parseInt(process.env.VUE_APP_CURRENT_VERSION) && this.webVersion !== 0) {
        this.$toast.info("Se refrescará su navegador web para que tenga la versión más reciente.");
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
      this.setWebVersion(parseInt(process.env.VUE_APP_CURRENT_VERSION));
    }, 100);
  },
  beforeUnmount() {
    // Remover el listener cuando se destruya el componente
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  methods: {
    ...mapMutations([
      "setUser",
      "setLocalSuperAdmin",
      "setBusinessLocalId",
      "setAuthenticatedFalse",
      "setWebVersion",
      "setDocumentTypeForPrint",
        "setReg_id"
    ]),
    finished_printing() {
      this.setDocumentTypeForPrint(0);
    },
    async getUserPermissions() {
      try {
        let response = await UserServices.infoUser();
        if (response.data.success) {
          if (response.data.data.force_logout) {
            await this.logoutAndClearData();
            window.alert(this.mensaje_moroso);
            return;
          }
          this.setUser(response.data.data);
          this.setBusinessLocalId(response.data.data.local);
          return
        }
        this.$toast.error(response.data.message);
        await this.logoutAndClearData();
      } catch (e) {
        console.log("error es", e);
        if (e.response.status === 500 || e.response.status === 401) {
          await this.logoutAndClearData();
          this.$toast.error("Hubo un error. Tiene que volver a iniciar sesión para continuar.");
        } else {
          this.$toast.error(e.toString());
        }
      }
    },
    async logoutAndClearData() {
      try {
        if (this.user.waiter === true) {
          let response = await UserServices.logoutUserRegId({
            platform: this.platform,
            reg_id: this.reg_id
          })
          if (response.data.success) {
            console.log(response.data.data)
          } else {
            console.log(response.data.message)
          }
        }
      } catch (e) {
        console.log("error en fcm logout", e)
      }
      this.unregisterServiceWorker()
      this.showModalConfirmNotification = false
      this.setAuthenticatedFalse();
      localStorage.clear();
      await UserServices.setBusinessHeader("");
      await UserServices.setAuthHeaders(null);
      await this.$router.push("/");
    },
    async activeOrdersNotificationPermissions() {
      console.log("solicitara permiso")
      let self = this;
      if (!this.configurationsAdminWeb.reservation && !this.configurationsAdminWeb.takeout && !this.configurationsAdminWeb.delivery) {
        return;
      }
      if ('Notification' in window) {
        Notification.requestPermission().then((result) => {
          console.log("result inicial", result)
          if (result === "granted") {
            console.log("Ya tiene permitido por defecto");
            self.showModalNotificationsAllowed = true
            //aceptado
          } else if (result === "prompt") {
            console.log("debe preguntar");
          } else if (result === "denied") {
            //denegado
            console.log("tiene denegado");
            self.showModalOptionalNotification = true;
          }
        });
      } else {
        this.notification.title = 'Error'
        this.notification.content = 'Las notificaciones no están soportadas en este navegador.'
        this.showPushUpModal = true
      }
    },
    async firestoreListennerApp() {
      if (this.businessIdentifier === '') {
        console.log("no puede hacer listen fs")
        return;
      }
      console.log("intentara listen")
      // if (this.user.admin || this.user.super_admin) {

      this.myTrack = new Audio(require("@/audio/audio.mp3"));

      this.subscription_firebase = onSnapshot(
          collection(
              db, `quickeat/${this.businessIdentifier}/${this.businessLocalId}`
          ),
          {includeMetadataChanges: true},
          (snapshot) => {
            if (this.initialListen) {
              console.log("hizo initial listen app.vue");
              this.initialListen = false;
            } else {
              snapshot.docChanges().forEach((snapshot) => {
                if (snapshot.type === "added") {
                  if (process.env.NODE_ENV === "development") {
                    console.log("se añadió info a app.vue", snapshot.doc.data());
                  }

                  if (snapshot.doc.data().status !== "0") {
                    return;
                  }

                  switch (snapshot.doc.data().type_delivery) {
                    case "1":
                    case 1:
                      if (!this.configurationsAdminWeb.reservation) {
                        return;
                      }
                      break;
                    case "2":
                    case 2:
                      if (!this.configurationsAdminWeb.takeout) {
                        return;
                      }
                      break;
                    case "3":
                    case 3:
                      if (!this.configurationsAdminWeb.delivery) {
                        return;
                      }
                      break;
                    case "4":
                    case 4:
                      return;
                  }

                  let self = this;
                  document.addEventListener("click", this.handleClickOutside);

                  this.myTrack.loop = true;
                  this.myTrack.play();

                  let response = snapshot.doc.data().message;

                  const notification = {
                    title: "Quick eat!",
                    options: {
                      body: response,
                      requireInteraction: true,
                      icon: "https://quickeat.s3-us-west-2.amazonaws.com/Digital+media/logo_app_quick_eat.png",
                      image:
                          "https://quickeat.s3-us-west-2.amazonaws.com/Digital+media/logo_app_quick_eat.png",
                      badge:
                          "https://quickeat.s3-us-west-2.amazonaws.com/Digital+media/logo_app_quick_eat.png",
                    },
                    events: {
                      onerror: function () {
                        //console.log('Error en la notif');
                      },
                      onclick: async function () {
                        self.myTrack.pause();
                        self.myTrack.currentTime = 0;
                        document.removeEventListener(
                            "click",
                            self.handleClickOutside
                        );
                        if (`${this.businessLocalId}` !== "0") {
                          await router.push("/orders");
                        } else {
                          let id_local = snapshot.doc.data().local_id;
                          let local_obj = {
                            id: id_local,
                            name: snapshot.doc.data().local_name,
                          };
                          store.commit("setLocalSuperAdmin", local_obj);
                          await router.push({ path: `/ordersSuperAdmin/${id_local}`, query: { headerTitle: `Órdenes - ${local_obj.name}` } })
                          // $setLocalSuperAdmin(local_obj)
                        }
                      },
                      onclose: function () {
                        self.myTrack.pause();
                        self.myTrack.currentTime = 0;
                        document.removeEventListener(
                            "click",
                            self.handleClickOutside
                        );
                      },
                      onshow: function () {
                      },
                    },
                  };
                  this.$notification.show(
                      notification.title,
                      notification.options,
                      notification.events
                  );
                  this.$toast.success(response);
                }
              });
            }
          }
      );
    },
    async requestPermissionAndGetToken(registration) {

      try {
        const token = await getToken(messaging, {vapidKey: 'BLqObJ9ZFsmP3nzWJcBSWyIbCNf-JZQ2czubvzS7cNDCmyvRpxVmXY1Jqdn8Chmy0_S6AwrP4cSegQekpFUrV2g', serviceWorkerRegistration: registration});
        if (token) {
          this.setReg_id(token)
          let response = await UserServices.updateRegId({
            reg_id: token,
            platform: this.platform,
            role: 3
          })
          if (response.data.success) {
            console.log("token front registrado con exito")
          } else {
            console.log("token front error")
          }
          onMessage(messaging, (payload) => {
            console.log("notif front", payload)
            const title = payload.data.title
            const body = payload.data.message
            const options = {
              body: body,
              icon: "https://quickeat.s3-us-west-2.amazonaws.com/Digital+media/logo_app_quick_eat.png",
            };
            registration.showNotification(payload.data.title, options);
            this.notification.title = title
            this.notification.content = body
            this.showPushUpModal = true
          })
        }
      } catch (error) {
        console.error('Error al solicitar permisos de notificación o al obtener el token de FCM:', error);
      }
    },

    registerFirebaseMessagingSW() {
      let self = this
      if (this.has_loaded_worker) {
        this.$toast.error("Ya se registró el service worker")
        return
      }
      if (messaging == null) {
        this.$toast.error("Este navegador web no es compatible con notificaciones push.")
        return
      }
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data.type === 'showModal') {
          this.showModal(event.data.title, event.data.body);
        }
      });
      const firebase_full_config = firebaseConfig
      firebase_full_config.user_token = this.user.token
      firebase_full_config.base_api = `${process.env.VUE_APP_API}/user/reg_id/`
      firebase_full_config.platform = this.platform
      if ('serviceWorker' in navigator) {

        navigator.serviceWorker.register('/firebase-messaging-sw_new.js?config=' + encodeURIComponent(JSON.stringify(firebase_full_config)))
            .then((registration) => {
              console.log('Service Worker registrado con éxito:', registration);
              self.has_loaded_worker = true
              setTimeout(() => {
                this.requestPermissionAndGetToken(registration);
              }, 5000)
            })
            .catch((error) => {
              console.error('Error al registrar el Service Worker:', error);
            });
      }
    },
    requestPermissionWaiter() {
      if (!this.isAuthenticated) {
        return
      }
      if ('Notification' in window) {
        Notification.requestPermission().then((permission) => {
          this.current_state_notif = permission
          if (permission === "granted") {
            this.showModalConfirmNotification = false
            this.registerFirebaseMessagingSW()
            this.current_state_notif = permission
          } else {
            this.showModalConfirmNotification = false
          }
        })
        setTimeout(() => {
          if (this.current_state_notif !== 'granted') {
            this.$toast.error("Debe actualizar la configuración en su navegador web.")
          }
        }, 300)
      } else {
        this.notification.title = 'Error'
        this.notification.content = 'Las notificaciones no están soportadas en este navegador.'
        this.showPushUpModal = true
      }
    },

    handleClickOutside() {
      // Detener la reproducción del sonido aquí
      if (this.myTrack.currentTime === 0) {
        return;
      }
      this.myTrack.pause();
      this.myTrack.currentTime = 0;

      // También puedes eliminar el event listener para evitar múltiples llamadas innecesarias
      document.removeEventListener("click", this.handleClickOutside);
    },
    unregisterServiceWorker() {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          console.log("reg es", registration)
          if (registration.active && registration.active.scriptURL.includes('/firebase-messaging-sw_new.js')) {
            registration.unregister().then((success) => {
              if (success) {
                this.has_loaded_worker = false
                console.log('Service Worker desregistrado con éxito:', registration);
              } else {
                console.error('Error al desregistrar el Service Worker.');
              }
            });
          }
        });
      }).catch((error) => {
        console.error('Error al obtener los Service Workers:', error);
      });
    },

    async handleBeforeUnload() {
      // Verificar que la app de Firebase existe antes de intentar eliminarla
      if (firebaseApp) {
        await firebase.deleteApp(firebaseApp)
      }
    }
  },
};
</script>
<style scoped>
#app__wrapper {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  background: #f7f7f7;
}

.main {
  height: 100%;
  overflow: auto;
}
</style>
