import * as firebase from "@firebase/app";
import { getMessaging } from 'firebase/messaging';
import "@firebase/auth";
import { getFirestore } from "firebase/firestore";
// import { getDatabase } from "firebase/database";

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyC4EjUNeTpFxIShztsw6Hjh3om3K06WPoY",
  authDomain: "quickeat-1475967802201.firebaseapp.com",
  databaseURL: "https://quickeat-1475967802201.firebaseio.com",
  projectId: "quickeat-1475967802201",
  storageBucket: "quickeat-1475967802201.appspot.com",
  messagingSenderId: "798343171799",
  appId: "1:798343171799:web:27a527487754f45f092a92",
  measurementId: "G-3MR2YE7J8N",
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
let messaging = null
try {
  messaging = getMessaging(firebaseApp);
} catch (error) {
  messaging = null
  console.log(error);
}

// utils
const db = getFirestore(firebaseApp);
// const trackingDatabase = getDatabase(firebaseApp)

// export utils/refs
export {
  db, firebaseConfig, messaging, firebase, firebaseApp
  // trackingDatabase
};
